'use strict';

var $ = require('jquery');
var animsition = require('animsition');
var imagesLoaded = require('imagesloaded');
var slick = require('slick-carousel');
//var object = require('object-fit-polyfill');

var global = {
  init: function(){
    
  },

  ready: function(){
    this.pageTransitions();
    this.menu();
    this.slideshow();
    $('body').on('contextmenu', 'img', function(e){ return false; });
  },
  
  resize:function(){
    // if($('.slideshow .slides').length){
    //   var $videos = $('.slideshow .slides').find('.video');
    //   $videos.each(function(i,v){
    //     var $vid = $(v).find('video');
    //     var width = $(v).width();
    //     if ($vid.width() > width){
    //       console.log(width);
    //       console.log($vid.width());
    //       $vid.css({'margin-left' : -($vid.width()-width)/2});
    //     }else{
    //       $vid.css({'margin-left' : 0});
    //     }
    //   })
    // }
  },
  
  scroll: function(){
  },

  pageTransitions: function () {
    var spans = '';
    for (var i=0;i<36;i++){
      spans += '<span style="animation-delay:'+(Math.random()+.2)+'s;"></span>';
    }
    //$('body').append('<div class="loader">'+ spans +'</div>');
    $(".animsition").animsition({
      inClass: 'fade-in',
      outClass: 'fade-out',
      inDuration: 1500,
      outDuration: 800,
      linkElement: '.transition-link',
      // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
      loading: true,
      loadingParentElement: 'body', //animsition wrapper element
      loadingClass: 'page-loading',
      loadingInner: '<div class="loader">'+ spans +'</div>', // e.g '<img src="loading.svg" />'
      timeout: true,
      timeoutCountdown: 50000,
      onLoadEvent: true,
      browser: [ 'animation-duration', '-webkit-animation-duration'],
      // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
      // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
      overlay : false,
      overlayClass : 'animsition-overlay-slide',
      overlayParentElement : 'body',
      transition: function(url){ window.location.href = url; }
    });
  },
  menu: function(){
    $('.menu-link').click(function(){
      $('body').toggleClass('menu-active');
        $('.menu-button').toggleClass('animate');  
      
    });
  },
  slideshow : function(){
    if ($('.slideshow').length){
           $('.slideshow .slides').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            console.log(slick);
            var currentSlideClasses = slick.$slides[currentSlide].className;
        if(currentSlideClasses.indexOf('video') !== -1){
          var video = $(slick.$slides[currentSlide]).find('video')[0];
          video.pause();
        }
        var slideClasses = slick.$slides[nextSlide].className;
        
          if(slideClasses.indexOf('video') !== -1){
            var video = $(slick.$slides[nextSlide]).find('video')[0];            
            if ( video.readyState === 4 ) {
              video.play();
              video.currentTime = 0;
            }
          }
        
      });
      $('.slideshow .slides').on('init', function(event,slick){
        
        var slideClasses = slick.$slides[0].className;
        setTimeout(function(){
          if(slideClasses.indexOf('video') !== -1){
            var video = $(slick.$slides[0]).find('video')[0];
            if ( video.readyState === 4 ) {
              video.play();
            }
          }
          
        },600);
      });
      imagesLoaded($('.slideshow')[0], function(){
        $('.slideshow').addClass('visible');
      });
      var options = {
        prevArrow: '<div class="slick-arrow arrow-left"></div>',
        nextArrow: '<div class="slick-arrow arrow-right"></div>',
        dots: true,
        fade: true,
        appendDots: '.slideshow-nav',
        arrows : true,
        slide: '.slide',
        rows:0,
        responsive: 
        [
          {
            breakpoint: 850,
            settings: {
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
      if ($('.slideshow').hasClass('home')){

        var autoplaySpeed = $('.slideshow').attr('data-autoplay-speed');
        var options = {
        prevArrow: '<div class="slick-arrow arrow-left"></div>',
        nextArrow: '<div class="slick-arrow arrow-right"></div>',
        dots: true,
        fade: true,
        appendDots: '.slideshow-nav',
        arrows : true,
        slide: '.slide',
        rows:0,
        responsive: 
        [
          {
            breakpoint: 1000,
            settings: {
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: autoplaySpeed || 3000
            }
          }
        ]
      };
        
        options.arrows = false;
        options.pauseOnHover = false;
        options.pauseOnFocus = false;
        options.autoplay = false;
        options.autoplaySpeed = autoplaySpeed || 3000;
      }
      $('.slideshow .slides').slick(options);
      if ($('.slideshow').hasClass('home')){
        setTimeout(function() {
          // $('.slides').slick('slickSetOption', 'autoplay', true, true);
        },3000);
      }
      // $('.slideshow .slides').on('afterChange', function(event, slick, currentSlide, nextSlide){
      //   var slideClasses = slick.$slides[currentSlide].className;
      //   if(slideClasses.indexOf('video') !== -1){
      //     var video = $(slick.$slides[currentSlide]).find('video');
      //     video[0].play();
      //   }
      // });
 
      
      
    }
  }
};
module.exports = global;