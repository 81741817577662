'use strict';

var $ = require('jquery');
window.$ = $;

var project = {
  init: function(){
  },

  ready: function(){
 		this.read_more();
    if ($('.project').length){
      var cat_link = $('body h1').attr('data-cat');
      console.log(cat_link);
      $('header a[href="'+ cat_link+'"]').parent().addClass('current-menu-item');
    }
  },
  
  resize:function(){
  },
  
  scroll: function(){
  },
	read_more: function(){
		$('.read-more-link').click(function(){
			$(this).toggleClass('info-open');
			$('.project-info').toggleClass('open');
			$('html,body').animate({
        scrollTop: $('.project-info').offset().top},
        'slow');
		})
	}

};
module.exports = project;