'use strict';

var $ = require('jquery');

var client = {
  init: function(){
  },

  ready: function(){
 		this.collabsibleSections();
  },
  
  resize:function(){
  },
  
  scroll: function(){
  },
  collabsibleSections: function(){
  	$('.group-title').click(function(){	
			$(this).parent().toggleClass('open');
		});
  }


};
module.exports = client;